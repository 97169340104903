.app-class {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

@media (max-width: 1200px) {

    /* Viewport Breakpoint */
    .app-class {
        display: block;
    }
}
